/**
 * Sensible defaults for processing a graphql response for a Craft entry in a page component.
 * @param {*} response
 * @param {string} entryKey
 * @returns
 */
export function useCraftPageEther({ error, data, pending }, entryKey = 'entry') {
    const { t } = useI18n();
    const craftEntry = computed(() => data?.value?.[entryKey]);
    const craftSeoData = computed(() => craftEntry.value);

    useEtherSeo(craftSeoData);

    watchEffect(() => {
        if (pending.value === true) {
            // Don't do anything while loading
            return;
        }

        if (!craftEntry?.value && unref(error)) {
            showError({
                statusCode: error.value?.statusCode || -1,
                statusMessage: error.value?.statusMessage || 'Internal server error',
                message: error.value?.toString(),
            });
        } else if (!craftEntry?.value) {
            showError({ statusCode: 404, statusMessage: t('error.message.404') });
        }
    });

    return {
        craftEntry
    };
}
